
export default {

    created() {

        let t = this;


        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;

            let p = getURLParams();

            axios.get('expert/' + p.id).then(res => {
                if (res.data.status == 1) {
                    let expert = res.data.options.item;

                    expert.actived_at = '2024-01-01 02:22:02';
                    if (expert.actived_at) {
                        expert.online_text = t.getElapsedTimeString(new Date(expert.actived_at), new Date()) + "前在线";
                    }

                    t.expert = expert;
                }
            });

        },

        goChat() {
            let t = this;

            if (t.expert.id == t.U.id) {
                A.err("无法与自己发起会话");
                return;
            }

            t.warningAlert().then(res => {
                if (res)
                    t.$router.push('/chat?oid=' + t.expert.id);
            });

        }
    },
    data() {
        return {
            search: {
                keywords: ''
            },
            expert: null,
            list: {
            },

        };
    },
};
