
export default {

    created() {

        let t = this;


        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;

            axios.get('article').then(res => {
                if (res.data.status == 1) {
                    t.list.article = res.data.options.items;
                }
            });

        },

        goArticle(item) {
            this.$router.push('/article?id=' + item.id)
        },

        goPublish() {
            this.$router.push('newarticle')
        }
    },
    data() {
        return {
            stats: {
                total: 99,
                owned: 1
            },
            search: {
                status: 'all',
                keywords: ''
            },
            list: {
                article: [
                    {
                        "id": 1,
                        "created_at": "6/2/2024 00:42:21",
                        "updated_at": "6/2/2024 00:42:21",
                        "img_url": "img/article/1.png",
                        "title": "有关冬季应对心脏病突发的有效小贴士，一睹为快！",
                        "user_id": 1,
                        expert: { name: '陈医生', img_url: '' },
                    },
                    {
                        "id": 2,
                        "created_at": "6/2/2024 00:42:21",
                        "updated_at": "6/2/2024 00:42:21",
                        "img_url": "img/article/1.png",
                        "title": "有关冬季应对心脏病突发的有效小贴士，一睹为快！",
                        "user_id": 2,
                        expert: { name: '姜医生', img_url: '' },
                    }
                ],
            },

        };
    },
    computed: {
        filteredArticles: function () {
            var arr = [];
            var arr2 = this.list.article;

            for (var i = 0; i < arr2.length; i++) {
                var item = arr2[i];
                if (this.search.keywords && item.title.indexOf(this.search.keywords) < 0)
                    continue;
                if (this.search.status == 1 && item.user_id != this.U.id)
                    continue;
                arr.push(item);
            }

            return arr;
        }
    }
};
