
export default {

    created() {

        let t = this;

        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {

            let t = this;

            let p = getURLParams();

            axios.get('unit').then(res => {
                if (res.data.status == 1) {
                    t.list.unit = res.data.options.items;
                    t.dict.unit = listToDict(t.list.unit);
                    if (p.unit_id) {
                        t.unit = t.dict.unit[p.unit_id];

                        setTimeout(function () {
                            function ensureCheckboxVisible(checkbox) {
                                // 获取滚动容器和目标复选框的位置信息
                                const scrollableDiv = document.getElementById('unitList');
                                const scrollableDivRect = scrollableDiv.getBoundingClientRect();
                                const checkboxRect = checkbox.getBoundingClientRect();

                                // 如果目标复选框在可视区域内，则无需滚动
                                if (checkboxRect.left >= scrollableDivRect.left && checkboxRect.right <= scrollableDivRect.right) {
                                    return;
                                }

                                // 计算滚动量，使目标复选框位于可视区域的中央
                                const scrollAmount = checkboxRect.left - scrollableDivRect.left - (scrollableDivRect.width - checkboxRect.width) / 2;

                                // 滚动到合适的位置
                                scrollableDiv.scrollLeft += scrollAmount;
                            }

                            ensureCheckboxVisible(document.getElementById('unitItem' + t.unit.id));
                        }, 100);

                    }
                }
            });

            axios.get('expert').then(res => {
                if (res.data.status == 1) {
                    t.list.expert = res.data.options.items;
                }
            });

        },

        goExpert(item) {
            this.$router.push('/expert?id=' + item.id)
        }
    },
    data() {
        return {
            search: {
                keywords: ''
            },
            unit: null,
            dict: {
                unit: {}
            },
            list: {
                unit: [
                ],
                expert: [
                ]
            },

        };
    },
    computed: {
        filteredExperts: function () {
            var arr = [];
            var arr2 = this.list.expert;

            for (var i = 0; i < arr2.length; i++) {
                var item = arr2[i];
                if (this.search.keywords && item.name.indexOf(this.search.keywords) < 0)
                    continue;
                if (this.unit && item.unit_id != this.unit.id)
                    continue;
                arr.push(item);
            }

            return arr;
        }
    }
};
