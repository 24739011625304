
import AppNav from "./components/nav";
import { Dialog } from "vant";
import Vue from 'vue'
export default {
  name: "Index",
  components: {
    AppNav,
  },
  data() {
    return {
      disabledScroll: false,
      showWarningBox: false
    };
  },
  methods: {
    afterLogin() {
      let t = this;

      axios.defaults.headers.common["Token"] = this.U.token;
      axios.defaults.headers.common["OpenID"] = this.U.open_id;
      t.initSharing();
      Vue.prototype.mode = t.U.role;
      if (localStorage['override_mode'])
        Vue.prototype.mode = Number(localStorage['override_mode']);

      if (Vue.prototype.mode == 1) {
        if (!t.U.name) {
          t.$router.replace('/profile');
        }
      }

      if (Vue.prototype.mode == 2) {
        if (t.$router.currentRoute.name == 'home') {
          t.$router.replace('/euser');
        }
      }
    },
    cancel() {
      this.resolveFun(false);
      this.showWarningBox = false;
    },
    confirm() {
      this.resolveFun(true);
      this.showWarningBox = false;
    }
  },
  created() {

    var t = this;

    Vue.prototype.startUrl = location.href;
    Vue.prototype.busying = false;

    Vue.prototype.afterLogin = t.afterLogin;
    Vue.prototype.mode = localStorage['override_mode'] ? Number(localStorage['override_mode']) : 1;

    Dialog.setDefaultOptions({ confirmButtonText: '确认' });

    axios.defaults.baseURL = '//hlyzxyh.cn/v1/';

    if (/localhost|dev/.test(location.host))
      axios.defaults.baseURL = '//localhost/hlyzxyh/api/public/v1/';

    Vue.prototype.back = function () {
      this.$router.go(-1);
    }

    Vue.prototype.goChats = function () {
      this.$router.push('/chats');
    }

    Vue.prototype.warningAlert = () => {

      t.showWarningBox = true;

      const myPromise = new Promise((resolve, reject) => {
        t.resolveFun = resolve;
      });

      return myPromise;

    }


    Vue.prototype.U = {
      name: '',
      avatar_url: '',
      token: '',
      id: '',
      open_id: '',
    };

    if (S.get('_u')) {
      Vue.prototype.U = S.get('_u');
      t.afterLogin();
    }

    let p = getURLParams();

    if (p.open_id) {
      axios.post('account/signInByOpenId', { 'open_id': p.open_id }).then(res => {
        if (res.data.status == 1) {
          let U = res.data.options.item;
          mirror(U, t.U);
          S.set('_u', U);
          t.afterLogin();
          t.refreshNav();
        }
      });
    }

  },
  mounted() {
    let t = this;

  },

};
