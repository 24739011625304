
import Vue from 'vue'
export default {
  name: "Nav",
  props: {},
  mounted() { },
  created() {

    var t = this;

    Vue.prototype.refreshNav = function () {

      var reg;

      if (t.mode == 1)
        reg = /\/(home|experts|chats|user)/i;
      else
        reg = /\/(articles|chats|euser)/i;

      t.$router.beforeEach((to, from, next) => {

        var r = reg.exec(to.path);

        if (r && r.length > 0) {
          t.page = r[1];
          t.showNav = true;
        }
        else
          t.showNav = false;

        next();
      });

      var r = reg.exec(location.pathname);

      if (r && r.length > 0) {
        t.page = r[1];
        t.showNav = true;
      }
      else
        t.showNav = false;

    }
    t.refreshNav();

  },
  methods: {

    go(page) {

      if (page == this.page) return;

      this.page = page;

      this.$router.replace(page);

    },
  
  },
  data() {
    return {
      resolveFun: null,
      showWarningBox: false,
      showQuickEntrances: false,
      page: "",
      showNav: false,
    };
  },
  components: {
  },
};
