

export default {

    created() {

        let t = this;

        t.f = {
            tel: ''
        };

        t.loadData();
    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;
        },

        submit() {
            let t = this;
            let f = t.f;

            if (t.busying)
                return;

            if (!f.tel) {
                A.err('请输入手机号或微信号');
                return;
            }

            t.busying = true;

            axios.post('user/bindVIP', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    mirror(res.data.options.item, t.U);
                    S.set('_u', t.U);
                    if (t.U.vip == 2) {
                        A.toast('绑定成功');
                        t.$router.replace('/user');
                    }
                    else {
                        A.err('未找到您的会员权益');
                    }

                }
            });

        },

    },
    data() {
        return {

            f: {
                tel: '',
            },

        };
    },
};
