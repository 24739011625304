
import { areaList } from '@vant/area-data';
export default {

    created() {

        let t = this;

        let p = getURLParams();

        if (p.back)
            t.showBack = true;

        mirror(t.U, t.f);
        
        t.loadData();
    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;
        },

        submit() {
            let t = this;
            let f = t.f;

            if (!f.name) {
                A.err('请输入姓名');
                return;
            }

            if (!f.birthday) {
                A.err('请选择出生日期');
                return;
            }

            if (!f.city) {
                A.err('请选择所在城市');
                return;
            }

            if (!f.height) {
                A.err('请输入身高');
                return;
            }

            if (!f.weight) {
                A.err('请输入体重');
                return;
            }

            if (t.busying)
                return;

            t.busying = true;

            axios.post('user/saveProfile', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    mirror(res.data.options.item, t.U);
                    S.set('_u', t.U);
                    if (t.showBack)
                        t.$router.replace('/user');
                    else
                        t.$router.replace('/home');
                }
            });

        },

        onSelectCity(options) {


            let arr = [options[0].name];
            if (options.length > 1)
                arr.push(options[1].name);

            arr = arr.unique();

            this.f.city = arr.join(',');
            this.showCitySelector = false;
        },

        onSelectDate(date) {
            this.f.birthday = date.format('yyyy/MM/dd');
            this.showDateSelector = false;
        }
    },
    data() {
        return {
            showBack: false,
            list: {
                unit: [],
            },
            currentDate: new Date(),
            minDate: new Date('1940/01/01'),
            maxDate: new Date(),
            showCitySelector: false,
            showDateSelector: false,
            f: {
                name: '',
                birthday: '',
                gender: 1,
                city: '',
                height: '',
                weight: ''
            },
            areaList: areaList,

        };
    },
};
