
export default {

    created() {

        let t = this;

        let p = getURLParams();

        if (p.back)
            t.showBack = true;

        t.loadData();
    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;
        },

        submit() {
            let t = this;
            let f = t.f;

            if (!f.title) {
                A.err('请填写标题');
                return;
            }

            if (!f.content) {
                A.err('请填写内容');
                return;
            }

            if (t.busying)
                return;

            t.busying = true;

            axios.post('article', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    t.$router.replace('/article?id=' + res.data.options.id);
                }
            });

        },


        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 600,
                minWdith: 600,
                maxHeight: 1000,
                maxWdith: 1000,
                fillColor: '#fff',
            }).toBlob((blob) => {

                let blobUrl = getObjectURL(blob);
                t.mainImgBlobDict[blobUrl] = blob;
                t.f.img_url = blobUrl;
                t.closeCropper();

            });
        },

        uploadPicture() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 16 / 9,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

    },
    data() {
        return {
            showBack: false,
            f: {
                title: '',
                img_url: '',
                content: ''
            },
            mainImgBlobDict: {},

cropper: null,

        };
    },
};
