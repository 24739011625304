import { render, staticRenderFns } from "./getvip.vue?vue&type=template&id=cc24fe4a&scoped=true"
import script from "./getvip.vue?vue&type=script&lang=js"
export * from "./getvip.vue?vue&type=script&lang=js"
import style1 from "./getvip.css?vue&type=style&index=1&id=cc24fe4a&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc24fe4a",
  null
  
)

export default component.exports