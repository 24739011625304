import { render, staticRenderFns } from "./medical.vue?vue&type=template&id=2382e7de&scoped=true"
import script from "./medical.vue?vue&type=script&lang=js"
export * from "./medical.vue?vue&type=script&lang=js"
import style1 from "./medical.css?vue&type=style&index=1&id=2382e7de&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2382e7de",
  null
  
)

export default component.exports