

export default {

    created() {

        let t = this;

        t.loadData();
    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;

            let p = getURLParams();

            if (p.id) {
                axios.get('article/' + p.id).then(res => {
                    if (res.data.status == 1) {
                        t.article = res.data.options.item;
                        if (!t.article)
                            t.$router.replace('/articles');
                    }
                });
            }
            else
                t.$router.replace('/articles');
        },

        goExpert(expert_id) {
            if (this.U.id) { $router.push('/expert?id=' + expert_id); }
        }
    },
    data() {
        return {
            article: null
        };
    },
};
